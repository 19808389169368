import React, { useState, useEffect } from "react";

export const useCallTimers = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const calculateTimer = (time) => {
    if (!time) return "00:00";

    const answerTime = new Date(time);
    const elapsed = Math.floor((currentTime - answerTime) / 1000);

    if(elapsed < 0) return "00:00";

    const minutes = Math.floor(elapsed / 60).toString().padStart(2, "0");
    const seconds = (elapsed % 60).toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  };

  return { calculateTimer };
};
