import { api } from "@data-phone/react-resource-server-auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    status: "idle",
    error: null,
};

export const fetchIntegrationsSlice = createAsyncThunk(
    "items/fetchIntegrationsSlice",
    async () => {
        const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/integrations/integrations`);
        return response.data;
    }
)

export const fetchIntegrationConfig = createAsyncThunk(
    "items/fetchIntegrationConfig",
    async (integrationName) => {
        const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/integrations/${integrationName}/config`);
        return {
            name: integrationName,
            config: response.data
        };
    }
)

const integrationsSlice = createSlice({
    name: "integrations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationsSlice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchIntegrationsSlice.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.items = action.payload.map((item) => {
                    return {
                        name:item,
                        config: {}
                    }
                });
            })
            .addCase(fetchIntegrationsSlice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(fetchIntegrationConfig.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchIntegrationConfig.fulfilled, (state, action) => {
                state.status = "succeeded";
                const index = state.items.findIndex((item) => item.name === action.payload.name);
                if (index !== -1) {
                    state.items[index] = {
                        ...state.items[index],
                        config: action.payload.config
                    }
                }
            })
            .addCase(fetchIntegrationConfig.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
    },
}); 

export default integrationsSlice.reducer;