import { useDispatch, useSelector } from "react-redux";

import { AdminWrapper } from "components";
import Dashboard from "pages/dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/globals.scss";
import "../src/index.css";

import PageNotFound from "pages/404";
import Header from "components/Header/Header";
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import CallCenter from "pages/CallCenter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAuthModule } from "@data-phone/react-resource-server-auth"
import { setUser } from "redux/slices/userSlice";



function App() {

  const { logout, data } = useAuthModule()
  const [isLoading, setIsLoading] = useState(true);
  const appPermission = data.sessionData && data.sessionData.permissions && data.sessionData.permissions['web-applications'] && data.sessionData.permissions['web-applications'].includes('teledash:any')
  const integrationsStatus = useSelector((state) => state.integrations.status);

  useEffect(() => {
    if (data.sessionData) {
      if(!appPermission) {
          window.location.href = process.env.REACT_APP_LOGIN_PORTAL
      }
      dispatch(setUser(data.sessionData))
      setIsLoading(false);
    }
  }, [data])

  const dispatch = useDispatch()

  return (
    <>
      {((isLoading && !data.sessionData) || !appPermission || integrationsStatus === "loading" ) && <Loader />}
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Header logout={logout} />
          {
            data.sessionData &&
            <AdminWrapper>
              <Routes>
                <Route path="" element={<Dashboard />} />
                <Route
                  path="call-center"
                  element={<CallCenter />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </AdminWrapper>
          }

        </BrowserRouter>
      </DndProvider>
    </>
  );
}

export default App;
