import { useInfoStatus } from "hooks/useInfoStatus";
import React from "react";

import useCallStateBorderColor from "hooks/useBorderColor";

import ico_outgoing from "../../../../../images/images/ico-call-outgoing.svg";
import ico_incoming from "../../../../../images/images/ico-call-incoming.svg";

import timerImg from "../../../../../images/images/ico-timer.svg";
import { useCallTimers } from "hooks/useCallTimers";

const OnCallItem = ({ phone, name, info, status, incoming }) => {
  const { infoText, statusText } = useInfoStatus(info);
  const borderColor = useCallStateBorderColor(info);
  const { calculateTimer } = useCallTimers();

  return (
    <div
      style={{ border: `2px solid ${borderColor}` }}
      className="AnimateCard card py-2 px-4 borderRound mt-2 rounded-lg relative"
    >
      <div className="flex items-center">
        <div className="counter mx-1 text-xs font-normal py-1 px-2 rounded">
          {phone}
        </div>
        <span className="text-xs font-medium">{name}</span>
      </div>
      <div className="UsersText flex items-center pt-1">
        <span>
          {info.incoming ? (
            <img className="w-6" src={ico_incoming} alt="" />
          ) : (
            <img
              className="w-6"
              src={statusText == "Outbound" ? ico_outgoing : ico_incoming}
              alt=""
            />
          )}
        </span>
        <p class="text-xs">
          <span className="pl-1.5">{infoText}</span>
          {/* <span class="pl-1.5">on call with</span>
          <span class="font-medium pl-1.5">{info?.calleeIDNumber}</span> */}
        </p>
      </div>
      <div className="timer flex items-center place-content-between py-1 px-2 rounded text-xs font-medium absolute  absolute top-0 right-0 absolute top-0 right-0">
        <span>
          {" "}
          <img src={timerImg} alt="" />
        </span>
        <span className="pl-1">{calculateTimer(info.answerTime)}</span>
      </div>
    </div>
  );
};

export default OnCallItem;
