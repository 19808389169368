import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useInfoStatus = (info) => {
  const [infoText, setInfoText] = useState("");
  const [statusText, setStatusText] = useState("");
  const ivrMenus = useSelector((state) => state.ivrMenus.items);
  const ringGroups = useSelector((state) => state.ringGroups.items);
  const extensions = useSelector((state) => state.extensions.items);
  const callCenterData = useSelector(
    (state) => state.callCenterDataSlice.items
  );

  useEffect(() => {
    // Logic for setting infoText
    if (ivrMenus?.result?.some((item) => item.extension == info.destination)) {
      let ivr = ivrMenus.result.filter(
        (item) => item.extension == info.destination
      );

      if (ivr[0]) {
        if (info.isHolding) {
          setInfoText(
            <span>
              {`call on hold with `}
              <span className="font-semibold">
                {ivr[0].name} ({info.destination})
              </span>
            </span>
          );
        } else if (info.isParked) {
          setInfoText(
            <span>
              {`call on park with `}
              <span className="font-semibold">
                {ivr[0].name} ({info.destination})
              </span>
            </span>
          );
        } else {
          setInfoText(
            <span className="font-semibold">
              {ivr[0].name} ({info.destination})
            </span>
          );
        }
      }
    } else if (
      ringGroups?.result?.some((item) => item.extension == info.destination)
    ) {
      let ivr = ringGroups?.result?.filter(
        (item) => item.extension == info.destination
      );
      if (ivr[0]) {
        if (info.isHolding) {
          setInfoText(
            <span>
              {`call on hold with `}
              <span className="font-semibold">
                {ivr[0].name} ({info.destination})
              </span>
            </span>
          );
        } else if (info.isParked) {
          setInfoText(
            <span>
              {`call on park with `}
              <span className="font-semibold">
                {ivr[0].name} ({info.destination})
              </span>
            </span>
          );
        } else {
          setInfoText(
            <span className="font-semibold">
              {ivr[0].name} ({info.destination})
            </span>
          );
        }
      }
    } else if (
      extensions.result &&
      extensions.result.length > 0 &&
      extensions?.result?.some((item) => item.extension == info.destination)
    ) {
      let extensionGroup = extensions.result.filter(
        (item) => item.extension == info.destination
      );
      if (extensionGroup[0]) {
        if (info.isHolding) {
          setInfoText(
            <span>
              {`call on hold with `}
              <span className="font-semibold">
                {extensionGroup[0].name} ({info.destination})
              </span>
            </span>
          );
        } else if (info.isParked) {
          setInfoText(
            <span>
              {`call on park with `}
              <span className="font-semibold">
                {extensionGroup[0].name} ({info.destination})
              </span>
            </span>
          );
        } else {
          setInfoText(
            <span>
              {`${
                info.answerState == "active" ? "Talking " : info.answerState
              } to `}
              <span className="font-semibold">
                {extensionGroup[0].name} ({info.destination})
              </span>
            </span>
          );
        }
      }
    } else if (
      callCenterData.result &&
      callCenterData.result.length > 0 &&
      callCenterData.result.some((item) => item.extension == info.destination)
    ) {
      let callCenterDataGroup = callCenterData.result.filter(
        (item) => item.extension == info.destination
      );
      if (callCenterDataGroup[0]) {
        setInfoText(
          <span>
            {` ${callCenterDataGroup[0].name} `}
            <span className="font-semibold">({info.destination})</span>
          </span>
        );
      }
    }else {
      if (info.isHolding) {
        setInfoText(
          <span>
            call on hold with
            <span className="font-semibold"> {info.destination}</span>
          </span>
        );
      }
     else if (info.isParked) {
        setInfoText(
          <span>
            call on park for &nbsp;
            <span className="font-semibold">{info.destination}</span>
          </span>
        );
      }
      
      else {
        setInfoText(
          <span>
            {`${
              info.answerState == "active" ? "Talking " : info.answerState
            } by `}
            <span className="font-semibold">{info.destination}</span>
          </span>
        );
      }
    }

    // Logic for setting statusText
    if (
      ivrMenus?.result?.some((item) => item.extension == info.callerIDNumber)
    ) {
      setStatusText("Outbound");
    } else if (
      ringGroups?.result?.some((item) => item.extension == info.callerIDNumber)
    ) {
      setStatusText("Outbound");
    } else if (
      extensions?.result?.some((item) => item.extension == info.callerIDNumber)
    ) {
      setStatusText("Outbound");
    } else {
      setStatusText("Inbound");
    }
  }, [info]);

  return { infoText, statusText };
};
