import useMemoizedOnCallItem from "hooks/useMemoizedOnCallItems";
import React from "react";
import { useSelector } from "react-redux";


const OnCallsList = ({sortBy,searchInputOnCallList}) => {
    let callsData = useSelector((state) => state.callDetails.data) || [];
    const memoizedOnCallItems = useMemoizedOnCallItem(callsData,sortBy,searchInputOnCallList)
    return <>{memoizedOnCallItems}</>
};


export default OnCallsList