import { api } from "@data-phone/react-resource-server-auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { phoneNumberFormatE164 } from "helper";
import qs from "qs";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

export const fetchHhExchangeNumbersLookups = createAsyncThunk("items/fetchHhExchangeNumbersLookups", async (number) => {

  const queryString = Array.isArray(number) ? `?${qs.stringify({ numbers: number }, { indices: false })}` : number;

  const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/integrations/hhaexchange/lookup/${queryString}`);
  return response.data;
});

const hhaexchange = createSlice({
  name: "hhaexchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHhExchangeNumbersLookups.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHhExchangeNumbersLookups.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (Array.isArray(action.payload)) {
          action.payload.forEach((item) => {
            state.items.push({
              ...item,
              HomePhone: phoneNumberFormatE164(item.HomePhone),
              Phone2: phoneNumberFormatE164(item.Phone2),
              Phone3: phoneNumberFormatE164(item.Phone3),
            });
          });
        } else {
          state.items.push({
            ...action.payload,
            HomePhone: phoneNumberFormatE164(action.payload.HomePhone),
            Phone2: phoneNumberFormatE164(action.payload.Phone2),
            Phone3: phoneNumberFormatE164(action.payload.Phone3),
          });
        }
      })
      .addCase(fetchHhExchangeNumbersLookups.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default hhaexchange.reducer;
