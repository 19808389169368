import React from 'react';

const CallCenterDetailComponent = ({ icon, title, element }) => {
    return (
        <>
            <div className='w-full px-6 flex flex-wrap items-center justify-center border-e'>
                <div className='my-2 inline-block w-[30px] text-center'>
                    <img src={icon} className='w-full' />
                </div>
                <div className='ml-2'>
                    <h4 className='text-lg font-medium mb-2'>{title}</h4>
                    {element}
                </div>
            </div>
        </>
    )
}

export default CallCenterDetailComponent;