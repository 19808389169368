import { useEffect, useRef } from "react";
import NewDashboardUsers from "./dashboard-users/NewDashboardUsers";
import { useDispatch, useSelector } from "react-redux";
import { fetchIntegrationsSlice } from "redux/slices/Integrations";
import { useGetIntegrationData } from "hooks/useIntegrationLookups";
const Dashboard = () => {
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.integrations.items);
  const integrationConfigFetchedRef = useRef(false);

  const { getIntegrationConfig } = useGetIntegrationData();

  useEffect(() => {
    if (integrations.length > 0 ) {
      if (integrationConfigFetchedRef.current) {
        return;
      }
      getIntegrationConfig();
      integrationConfigFetchedRef.current = true;
    }
  }, [integrations]);

  useEffect(() => {
    if(integrations.length === 0){
      dispatch(fetchIntegrationsSlice());
    }
  }, []);
  return (
    <>
      <NewDashboardUsers />
    </>
  );
};

export default Dashboard;
