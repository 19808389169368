import NewCallItem from "pages/dashboard/dashboard-users/call-list/call-item-new";
import { useMemo } from "react";

const useMemoizedCallItems = (callsData, display, handleRecord, handleBridgeCall) => {
  const memoizedCallItems = useMemo(() => {
    return callsData.map((item) => {
      return (
          <NewCallItem
            display={display}
            key={item.callUUID}
            phone={item.callerIDNumber}
            name={item.callerIDName}
            info={item}
            status={item.direction}
            handleRecord={handleRecord}
            handleBridgeCall={handleBridgeCall}
          />
      );
    });
  }, [callsData]);

  return memoizedCallItems;
};

export default useMemoizedCallItems;
