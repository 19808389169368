import { useEffect, useRef, useState } from "react";
import { fetchIntegrationConfig } from "redux/slices/Integrations";
import { fetchHhExchangeNumbersLookups } from "redux/slices/Integrations/hhaexchange";

const { useSelector, useDispatch } = require("react-redux");

export const useIntegrationLookups = () => {
  const dispatch = useDispatch();

  const fetchedCallerIDs = useRef(new Set());
  const integrationsRef = useRef(null);
  const hhaexchangeRef = useRef([]);

  const integrations = useSelector((state) => state.integrations.items);
  const hhaexchange = useSelector((state) => state.hhaexchange.items);

  useEffect(() => {
    integrationsRef.current = integrations;
  }, [integrations]);

  useEffect(() => {
    hhaexchangeRef.current = hhaexchange;
  }, [hhaexchange]);

  return {
    fetchIntegrationLookups: (number) => {
      if (!number) {
        return;
      }

      if (Array.isArray(number)) {
        fetchedCallerIDs.current = new Set(number);
      } else if (typeof number === "string") {
        if (fetchedCallerIDs.current.has(number)) {
          return;
        }

        fetchedCallerIDs.current.add(number);
      }

      if (integrationsRef.current && integrationsRef.current.length > 0) {
        integrationsRef.current.forEach((item) => {
          switch (item.name) {
            case "hhaexchange":
              if (Array.isArray(number)) {
                const numberInfo = number.filter((number) => !hhaexchangeRef.current.find((item) => item.HomePhone == number || item.Phone2 == number || item.Phone3 == number));
                dispatch(fetchHhExchangeNumbersLookups(numberInfo));
                break;
              } else if (typeof number === "string") {
                const numberInfo = hhaexchangeRef.current.find((item) => item.HomePhone == number || item.Phone2 == number || item.Phone3 == number);
                if (!numberInfo) {
                  dispatch(fetchHhExchangeNumbersLookups(number));
                }
                break;
              }
            default:
              break;
          }
        });
      }
      return;
    },
  };
};

export const useGetIntegrationData = () => {
  const dispatch = useDispatch();

  const integrationsRef = useRef(null);
  const hhaexchangeRef = useRef(null);

  const integrations = useSelector((state) => state.integrations.items);
  const hhaexchange = useSelector((state) => state.hhaexchange.items);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    integrationsRef.current = integrations;
    hhaexchangeRef.current = hhaexchange;
    setIsFetching(!isFetching);
  }, [integrations, hhaexchange]);

  return {
    getIntegrationsData: (number) => {
      const data = [];
      if (integrationsRef.current && integrationsRef.current.length > 0) {
        integrationsRef.current.forEach((item) => {
          switch (item.name) {
            case "hhaexchange":
              const result = hhaexchangeRef.current.find((item) => item.HomePhone == number || item.Phone2 == number || item.Phone3 == number);
              if (result && item.config?.ent_id) {
                let url = "";
                data.push({
                  type: "hhaexchange",
                  onClick: () => {
                    if (result.RecordType && result.RecordType === "Caregiver") {
                      url = `https://app.hhaexchange.com/${item.config.ent_id}/Aide/Aide.aspx?AideId=${result.ID}`;
                    } else if (result.RecordType && result.RecordType === "Patient") {
                      url = `https://app.hhaexchange.com/${item.config.ent_id}/Patient/InternalPatientInfo.aspx?PatientId=${result.ID}`;
                    }
                    window.open(url, "_blank");
                  },
                  data: result,
                });
              }
              break;
            default:
              break;
          }
        });

        return data;
      }
    },
    getIntegrationConfig: () => {
      if (integrationsRef.current && integrationsRef.current.length > 0) {
         integrationsRef.current.forEach((item) => {
           switch (item.name) {
             case "hhaexchange":
                if(!item.config?.ent_id){
                  dispatch(fetchIntegrationConfig(item.name));
                }
             default:
               break;
           }
         })
      }
    },
    isFetching,
  };
};
