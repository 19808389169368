import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Create an async thunk to fetch data from the API

export const updateCallCenterCalls = createAsyncThunk(
  "items/updateCallCenterCalls",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeCallCenterCallByAgentId = createAsyncThunk(
  "items/removeCallCenterCallByAgentId",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeAllCallCenterHoldCalls = createAsyncThunk(
  "items/removeAllCallCenterHoldCalls",
  () => {
    return true;
  }
);

// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const callCenterCalls = createSlice({
  name: "active",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(updateCallCenterCalls.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCallCenterCalls.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (Array.isArray(action.payload)) {
          state.items = action.payload.map((call) => {
            call.agentName = "";
            return call;
          });
          return;
        }

        const callIndex = state.items.findIndex(
          (call) => call.sessionUUID === action.payload.sessionUUID
        );

        if (callIndex !== -1) {
          action.payload.agentName = "";
          state.items[callIndex] = action.payload;
        } else {
          action.payload.agentName = "";
          state.items = [...state.items, action.payload];
        }
      })
      .addCase(updateCallCenterCalls.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeCallCenterCallByAgentId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeCallCenterCallByAgentId.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (agent) => agent.sessionUUID === action.payload
        );
        if (index !== -1) {
          state.items.splice(index, 1);
        }
      })
      .addCase(removeCallCenterCallByAgentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeAllCallCenterHoldCalls.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllCallCenterHoldCalls.fulfilled, (state) => {
        state.status = "succeeded";
        state.items = [];
      })
      .addCase(removeAllCallCenterHoldCalls.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default callCenterCalls.reducer;
