import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../slices/appSlice';

const EXPIRATION_TIME_MS = 24 * 60 * 60 * 1000; // 24 hour

const expireTransform = createTransform(
  (inboundState, key) => {
    return { ...inboundState, _persistedAt: Date.now() };
  },
  (outboundState, key) => {
    const now = Date.now();
    if (outboundState && outboundState._persistedAt) {
      const age = now - outboundState._persistedAt;
      if (age > EXPIRATION_TIME_MS) {
        return undefined; 
      }
    }
    return outboundState;
  },
  { whitelist: ['hhaexchange'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['hhaexchange'],
  transforms: [expireTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
