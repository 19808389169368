import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Create an async thunk to fetch data from the API

export const updateActiveCallsCallCenter = createAsyncThunk(
  "items/updateActiveCallsCallCenter",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeActiveCallsCallCenter = createAsyncThunk(
  "items/removeActiveCallsCallCenter",
  async (data) => {
    try {
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const removeAllAactiveCallCennterData = createAsyncThunk(
  "items/removeAllAactiveCallCennterData",
  () => {
    return true;
  }
);

// Create an async thunk to post a new item to the API

// Define the initial state
const initialState = {
  items: [],
  status: "idle",
  error: null,
};

// Define the slice
const callCenterCalls = createSlice({
  name: "active",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add the async thunk actions to the reducer
    builder
      .addCase(updateActiveCallsCallCenter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateActiveCallsCallCenter.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (Array.isArray(action.payload)) {
          state.items = action.payload.map((call) => {
            call.agentName = "";
            return call;
          });
          return;
        }

        const callIndex = state.items.findIndex(
          (call) => call.memeberUUID === action.payload.memeberUUID
        );

        if (callIndex !== -1) {
          action.payload.agentName = "";
          state.items[callIndex] = action.payload;
        } else {
          action.payload.agentName = "";
          state.items = [...state.items, action.payload];
        }
      })
      .addCase(updateActiveCallsCallCenter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeActiveCallsCallCenter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeActiveCallsCallCenter.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (agent) => agent.memberSessionUUID === action.payload
        );
        if (index !== -1) {
          state.items.splice(index, 1);
        }
      })
      .addCase(removeActiveCallsCallCenter.rejected, (state, action) => {
        state.status = "failed";
        // state.error = action.error.message;
      })
      .addCase(removeAllAactiveCallCennterData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeAllAactiveCallCennterData.fulfilled, (state) => {
        state.status = "succeeded";
        // Remove the members array from all queues
        state.items = [];
      })
      .addCase(removeAllAactiveCallCennterData.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default callCenterCalls.reducer;
